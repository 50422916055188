import React from 'react'
import classNames from 'classnames'
import { Swiper as SwiperClass } from 'swiper'
import Button, { ButtonTheme } from 'Components/touchables/Button'
import Row from 'Components/Row/Row'
import ChevronLeft from 'Assets/icon-chevron-left.inline.svg'
import styles from './SliderControls.module.scss'

export type SliderControlsProps = {
  className?: string
  controlClassName?: string
  swiper: SwiperClass | null
  slideIndex: number
  totalCount: number
  slidesPerView?: number
  theme?: ButtonTheme
}

const SliderControls = (props: SliderControlsProps) => {
  const {
    className = '',
    controlClassName = '',
    swiper,
    slideIndex,
    totalCount,
    slidesPerView = 1,
    theme,
    ...other
  } = props

  return (
    <Row className={classNames([styles.root, className])} {...other}>
      <Control
        className={controlClassName}
        onClick={() => swiper && swiper.slidePrev()}
        disabled={slideIndex === 0}
        theme={theme}
      />
      <Control
        className={controlClassName}
        onClick={() => swiper && swiper.slideNext()}
        disabled={slideIndex >= totalCount - slidesPerView}
        theme={theme}
      />
    </Row>
  )
}

const Control = ({
  className,
  onClick,
  disabled,
  theme,
}: {
  className?: string
  onClick: () => void
  disabled: boolean
  theme?: ButtonTheme
}) => {
  return (
    <Button
      className={classNames([styles.control, className])}
      variant="outline"
      theme={theme}
      iconStart={ChevronLeft}
      disabled={disabled}
      onClick={onClick}
    />
  )
}

export default SliderControls
