/*
 * Parallax Utils
 */
import { usePageScrollPosition, viewport } from 'Utils/common'
import { MutableRefObject, useCallback, useEffect } from 'react'

export function parallax3D(root: HTMLElement, content: HTMLElement) {
  let prevX: number, prevY: number

  const transform = (x: number, y: number, deg: number) => {
    content.style.setProperty('transform', `rotate3d(${y}, ${x}, 0, ${deg}deg)`)
  }

  document.addEventListener('mousemove', (e) => {
    const { clientX, clientY, pageY } = e
    if (pageY > root.offsetHeight) {
      return
    }

    const { width, height } = viewport()
    const STRENGTH = 3
    const x = (2 * (clientX - 0.5 * width)) / width
    const y = ((2 * (clientY - 0.5 * height)) / height) * -1
    const deg = (Math.abs(x) + Math.abs(y)) * STRENGTH

    transform(x, y, deg)

    // TODO: Заккоментировали, потому что в Chrome с определенной версии наблюдается ошибка: мерцают изображения.
    // const dx = prevX - x
    // const dy = prevY - y
    // const segmentLength = Math.sqrt(dx * dx + dy * dy)
    // if (segmentLength > 0.2) {
    // Если происходит резкий скачок, то включаем плавную анимацию на уровне CSS
    // const duration = 100
    // content.style.setProperty('transition-duration', `${duration}ms`)
    // transform(x, y, deg)
    // Потом transition нужно убрать, чтобы анимация на курсор реагировала моментально
    // setTimeout(() => content.style.setProperty('transition-duration', ''), duration)
    // } else {
    //   transform(x, y, deg)
    // }
    // prevX = x
    // prevY = y
  })
}

export const parallaxTranslate = (
  item: MutableRefObject<HTMLImageElement | null>,
  params: {
    value: number
    skew?: number
    rotate?: number
  },
) => {
  if (item.current) {
    const value = [
      `translateY(${params.value}px)`,
      !params.skew ? '' : `skewX(${params.skew}deg)`,
      !params.rotate ? '' : `rotate(${params.rotate}deg)`,
    ]
    item.current.style.setProperty('transform', value.join(' '))
    // Помогает исправить проблему: при резком скролле видно начальное положение иллюстраций
    item.current.style.setProperty('opacity', '1')
  }
}

export const useScrollParallax = (
  container: MutableRefObject<HTMLElement | null>,
  onScroll: (value: number) => void,
  offsetStart = 0,
) => {
  const processScroll = useCallback(
    (scrollY: number) => {
      if (scrollY && container.current) {
        const containerTop = container.current.offsetTop
        const containerHeight = container.current.offsetHeight
        const absScroll = Math.abs(scrollY)
        const viewportHeight = Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0,
        )
        const isSectionAtScreen =
          containerTop < absScroll + viewportHeight + offsetStart &&
          containerTop + containerHeight > absScroll

        if (isSectionAtScreen) {
          onScroll((absScroll - containerTop) * -1 - offsetStart)
        }
      }
    },
    [onScroll, offsetStart],
  )

  usePageScrollPosition(({ currPos }) => processScroll(currPos.y), [processScroll], 0)

  useEffect(() => {
    processScroll(window.scrollY)
  }, [])
}
