import React, { MutableRefObject, useCallback, useRef } from 'react'
import classNames from 'classnames'
import { get } from 'lodash'
import { parallaxTranslate, useScrollParallax } from 'Utils/parallax'
import { MQ, useMediaMatches } from 'Utils/mq'
import { ImageLocal } from 'Typings/api'
import { fadeUp } from 'Utils/animations'
import { localImageUrl } from 'Utils/api'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import Text from 'Components/texts/Text'
import Button from 'Components/touchables/Button'
import Phone1 from 'Assets/phone-1.inline.png'
import Phone2 from 'Assets/phone-2.inline.png'
import Tablet from 'Assets/tablet.inline.png'
import Laptop from 'Assets/laptop.inline.png'
import styles from './DevSection.module.scss'

type DevSectionProps = {
  data: {
    content: {
      text: string
      description: string
      image: ImageLocal
    }
    background: ImageLocal
    illustration_type: string
    button_title?: string
    button_link?: string
  }
}

const ILLUSTRATION_TYPES = {
  tablet: 'Tablet',
  phones: 'Phones',
  laptop: 'Laptop',
}

const DevSection = (props: DevSectionProps) => {
  const root = useRef<HTMLElement>(null)
  const {
    data: { background, content, illustration_type, button_title, button_link },
  } = props
  const icon = localImageUrl(content?.image)

  return (
    <BaseContainer
      ref={root}
      className={classNames([
        styles.root,
        icon ? styles.hasIcon : styles.noIcon,
        get(
          {
            [ILLUSTRATION_TYPES.tablet]: styles.isTablet,
            [ILLUSTRATION_TYPES.phones]: styles.isPhones,
            [ILLUSTRATION_TYPES.laptop]: styles.isLaptop,
          },
          illustration_type,
        ),
      ])}
      contentClassName={styles.content}
      Component="section"
      vertical={[ILLUSTRATION_TYPES.laptop].includes(illustration_type) ? 'sm' : 'md'}
      style={{ background: `url(${localImageUrl(background)})` }}
    >
      <Illustrations container={root} type={illustration_type} />
      <div className={styles.texts}>
        {icon && (
          <img className={styles.icon} src={icon} alt="Icon" {...fadeUp({ index: 0 })} />
        )}
        <Text
          className={styles.heading}
          variant={icon ? 'md-bold' : 'heading'}
          {...fadeUp({ index: 1 })}
        >
          {content.text}
        </Text>
        {content.description && (
          <Text
            className={styles.description}
            variant={icon ? 'sm' : 'md'}
            {...fadeUp({ index: 2 })}
          >
            {content.description}
          </Text>
        )}
        {button_title && button_link && (
          <div
            className={styles.button}
            /* Применяем анимацию к контейнеру, чтобы она не накладывалась на hover-анимацию */
            {...fadeUp({ index: 2 })}
          >
            <Button title={button_title} link={button_link} theme="alt" />
          </div>
        )}
      </div>
    </BaseContainer>
  )
}

function Illustrations({
  container,
  type,
}: {
  container: MutableRefObject<HTMLElement | null>
  type: string
}) {
  const phone1 = useRef<HTMLImageElement>(null)
  const phone2 = useRef<HTMLImageElement>(null)
  const tablet = useRef<HTMLImageElement>(null)
  const laptop = useRef<HTMLImageElement>(null)
  const isTabletScreen = useMediaMatches({ maxWidth: MQ.breakpoints.tablet })
  const offsetStart = isTabletScreen ? 0 : 50

  const onParallaxScroll = useCallback((value: number) => {
    if (type === ILLUSTRATION_TYPES.phones) {
      const phonesOffsetMultiplier = 0.8
      parallaxTranslate(phone1, { value, skew: value / 50 })
      parallaxTranslate(phone2, {
        value: value * phonesOffsetMultiplier,
        skew: -value / 50,
      })
    } else if (type === ILLUSTRATION_TYPES.tablet) {
      parallaxTranslate(tablet, { value, skew: value / 20 })
    } else if (type === ILLUSTRATION_TYPES.laptop) {
      parallaxTranslate(laptop, { value, rotate: -value / 30 })
    }
  }, [])

  useScrollParallax(container, onParallaxScroll, offsetStart)

  if (type === ILLUSTRATION_TYPES.phones) {
    return (
      <div className={styles.phones}>
        <img ref={phone1} className={styles.phone1} src={Phone1} alt="" />
        <img ref={phone2} className={styles.phone2} src={Phone2} alt="" />
      </div>
    )
  }

  if (type === ILLUSTRATION_TYPES.tablet) {
    return <img ref={tablet} className={styles.tablet} src={Tablet} alt="" />
  }

  if (type === ILLUSTRATION_TYPES.laptop) {
    return <img ref={laptop} className={styles.laptop} src={Laptop} alt="" />
  }

  return null
}

export default DevSection
