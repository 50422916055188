import React from 'react'
import classNames from 'classnames'
import Text from 'Components/texts/Text'
import ReactMarkdown from 'react-markdown'
import styles from './CountTexts.module.scss'

export type CountTextsProps = {
  className?: string
  countClassName?: string
  textsClassName?: string
  count: string
  heading?: string
  description?: string
}

const CountTexts = (props: CountTextsProps) => {
  const {
    className = '',
    countClassName = '',
    textsClassName = '',
    count,
    heading,
    description,
    ...other
  } = props

  return (
    <div className={classNames([styles.root, className])} {...other}>
      <Text className={classNames([styles.count, countClassName])} variant="heading">
        <ReactMarkdown allowDangerousHtml>{count}</ReactMarkdown>
      </Text>
      <div className={classNames([styles.texts, textsClassName])}>
        {heading && <Text className={styles.heading}>{heading}</Text>}
        {description && (
          <Text className={styles.description} variant="sm">
            {description}
          </Text>
        )}
      </div>
    </div>
  )
}

export default CountTexts
